import React from "react";
import Layout from "../components/layout";
import { graphql } from 'gatsby';
import { ArrowTrendingUpIcon, ClockIcon, BanknotesIcon } from '@heroicons/react/24/outline'
import SEO from "../components/seo/seo-new"
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';

export const accountantQuery = graphql`
query MyQueryAccountant($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
            ns
            data
            language
            }
        }
    }
}
`

export default function Accountants({data}) {
  const { t } = useTranslation();
  const lang = data.locales.edges[0].node.language

  const features = [
    {
      name: t('Grow Your Client Base'),
      description:
        t('Spend less time on data entry work and let taxomate take care of the manual work. Spend more time on growing your client base and providing excellent service to your customers.'),
      icon: ArrowTrendingUpIcon,
    },
    {
      name: t('Save Time and Avoid Mistakes'),
      description:
        t('With automatic posting of transactions and settlements to QuickBooks or Xero, taxomate saves countless hours. Avoid errors with automatic import into QuickBooks or Xero'),
      icon: ClockIcon,
    },
    {
      name: t('Partner Program'),
      description:
        t('All partners receive premium support and discounts on bulk imports. We will be developing a directory of all our partner accountants and bookkeepers.'),
      icon: BanknotesIcon,
    },
  ]
  const title = t("ecommerce Integration Software for Accountants and Bookkeepers")
  const description = t("ecommerce Integration Software for Accountants and Bookkeepers. Learn more about why accountants and bookkeepers trust taxomate as their accounting software integration solution!")

    return (
      <Layout>
        <SEO
          title={title}
          description={description}
          image="https://res.cloudinary.com/taxomate/image/upload/v1630007980/taxomate-social-card_tozy38.jpg"
          pathname='/taxomate-accountants-bookkeepers/'
          originalPathname='/taxomate-accountants-bookkeepers/'
          lang={lang}
          alternate
        />



    <section className="mt-16 mx-auto max-w-7xl px-4 sm:mt-24 py-20">
          <div className="text-center">
            <h1 className="text-4xl tracking-tight font-bold text-gray-900 sm:text-5xl md:text-6xl">
              <span className="block xl:inline"><Trans>ecommerce Bookkeeping Integration Software</Trans> </span>{' '}
              <span className="block text-blue-600 xl:inline"><Trans>for Accountants and Bookkeepers</Trans></span>
            </h1>
            <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
              <Trans>Looking for the easiest and most affordable QuickBooks, Wave, and Xero integration for clients? </Trans>

            <Trans>taxomate has you covered!</Trans>
            </p>
            <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
              <div className="rounded-md shadow">
                <a
                  href="/accounting-bookkeeping-registration"
                  className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10"
                >
                <Trans>Get Started</Trans>
                </a>
              </div>
            </div>
          </div>
        </section>

    <section className="py-20 bg-blue-100">
      <div className="max-w-xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
        <h2 className="sr-only"><Trans>A better way to send money.</Trans></h2>
        <dl className="space-y-10 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-8">
          {features.map((feature) => (
            <div key={feature.name}>
              <dt>
                <div className="flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                  <feature.icon className="h-6 w-6" aria-hidden="true" />
                </div>
                <p className="mt-5 text-lg leading-6 font-medium text-gray-900">{feature.name}</p>
              </dt>
              <dd className="mt-2 text-base text-gray-500">{feature.description}</dd>
            </div>
          ))}
        </dl>
      </div>
    </section>

    <section className="relative py-20 bg-white">
      <div className="hidden absolute top-0 inset-x-0 h-1/2 bg-gray-50 lg:block" aria-hidden="true" />
      <div className="max-w-7xl mx-auto bg-indigo-600 lg:bg-transparent lg:px-8">
        <div className="lg:grid lg:grid-cols-12">
          <div className="relative z-10 lg:col-start-1 lg:row-start-1 lg:col-span-4 lg:py-16 lg:bg-transparent">
            <div className="absolute inset-x-0 h-1/2 bg-gray-50 lg:hidden" aria-hidden="true" />
            <div className="max-w-md mx-auto px-4 sm:max-w-3xl sm:px-6 lg:max-w-none lg:p-0">
              <div className="aspect-w-10 aspect-h-6 sm:aspect-w-2 sm:aspect-h-1 lg:aspect-w-1">
                <img
                  className="object-cover object-center rounded-3xl shadow-2xl"
                  src="https://images.unsplash.com/photo-1507207611509-ec012433ff52?ixlib=rb-1.2.1&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&auto=format&fit=crop&w=934&q=80"
                  alt=""
                />
              </div>
            </div>
          </div>

          <div className="relative bg-indigo-600 lg:col-start-3 lg:row-start-1 lg:col-span-10 lg:rounded-3xl lg:grid lg:grid-cols-10 lg:items-center">
            <div className="hidden absolute inset-0 overflow-hidden rounded-3xl lg:block" aria-hidden="true">
              <svg
                className="absolute bottom-full left-full transform translate-y-1/3 -translate-x-2/3 xl:bottom-auto xl:top-0 xl:translate-y-0"
                width={404}
                height={384}
                fill="none"
                viewBox="0 0 404 384"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect x={0} y={0} width={4} height={4} className="text-indigo-500" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width={404} height={384} fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />
              </svg>
              <svg
                className="absolute top-full transform -translate-y-1/3 -translate-x-1/3 xl:-translate-y-1/2"
                width={404}
                height={384}
                fill="none"
                viewBox="0 0 404 384"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect x={0} y={0} width={4} height={4} className="text-indigo-500" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width={404} height={384} fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />
              </svg>
            </div>
            <div className="relative max-w-md mx-auto py-12 px-4 space-y-6 sm:max-w-3xl sm:py-16 sm:px-6 lg:max-w-none lg:p-0 lg:col-start-4 lg:col-span-6">
              <h2 className="text-4xl font-bold text-white" id="join-heading">
                  <Trans> Interested in Joining Our Partner Program?</Trans>
              </h2>
              <p className="text-lg text-white">
                  <Trans>Click Get Started to fill out the form. We will be in touch shortly.</Trans>


              </p>
              <a
                className="block w-full py-3 px-5 text-center bg-white border border-transparent rounded-md shadow text-base font-medium text-indigo-700 hover:bg-gray-50 sm:inline-block sm:w-auto"
                href="/accounting-bookkeeping-registration"
              >
                  <Trans>Get Started</Trans>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>

        </Layout>
)}