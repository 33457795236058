import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import Facebook from './Facebook'
import Twitter from './Twitter'



function SEO({ description, lang, meta, image, title, pathname, article, FAQ, faqs, first_publication_date, last_publication_date, alternate, originalPathname }) {
    const site = useStaticQuery(
        graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
            facebook
            twitter
          }
        }
        allLocale {
            nodes {
            language
            }
        }
      }

    `
    )

    const facebook = site.site.siteMetadata.facebook
    // const twitter = site.siteMetadata.twitter
    const url = pathname
    const metaDescription = description || site.site.siteMetadata.description
    const host = site.site.siteMetadata.siteUrl
    const canonical = pathname ? `${site.site.siteMetadata.siteUrl}${pathname}` : null
    // const alternate = pathname2 ? `${site.site.siteMetadata.siteUrl}${pathname}` : null
    // const image =
    //     metaImage && metaImage.src
    //         ? `${site.siteMetadata.siteUrl}${metaImage.src}`
    //         : null




    const itemListElement = [
        {
            '@type': 'ListItem',
            item: {
                '@id': canonical,
                name: 'Homepage',
            },
            position: 1,
        },
    ]


    let schemaFAQ = null
    if (FAQ) {
        schemaFAQ = {
            '@context': 'http://schema.org',
            '@type': 'FAQPage',
            "mainEntity": [


                    faqs.map((faq) => (
                        {
                            "@type": "Question",
                            "name": faq.question.text,
                            "acceptedAnswer": {
                                "@type": "answer",
                                "text": faq.answer.text,
                            }
                        }
                    ))
            ]
        }
    }

    let schemaArticle = null


    const author = "Michael Schwartz"

    if (article) {
        schemaArticle = {
            '@context': 'http://schema.org',
            '@type': 'Article',
            author: {
                '@type': 'Person',
                name: author,
            },
            copyrightHolder: {
                '@type': 'Person',
                name: author,
            },
            copyrightYear: '2019',
            creator: {
                '@type': 'Person',
                name: author,
            },
            publisher: {
                '@type': 'Organization',
                name: author,
                logo: {
                    '@type': 'ImageObject',
                    url: ``,
                },
            },
            datePublished: first_publication_date,
            dateModified: last_publication_date,
            description: metaDescription,
            headline: title,
            inLanguage: lang,
            url: url,
            name: title,
            image: {
                '@type': 'ImageObject',
                url: image,
            },
            mainEntityOfPage: url,
        }
        // Push current blogpost into breadcrumb list
        itemListElement.push({
            '@type': 'ListItem',
            item: {
                '@id': url,
                name: title,
            },
            position: 2,
        })
    }

    const breadcrumb = {
        '@context': 'http://schema.org',
        '@type': 'BreadcrumbList',
        description: 'Breadcrumbs list',
        name: 'Breadcrumbs',
        itemListElement,
    }
    const schemaOrgWebPage = {
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        url: url,
        headline: title,
        inLanguage: lang,
        mainEntityOfPage: url,
        description: description,
        name: title,
        author: {
            '@type': 'Organization',
            name: "taxomate",
        },
        copyrightHolder: {
            '@type': 'Organization',
            name: "taxomate",
        },
        copyrightYear: '2022',
        creator: {
            '@type': 'Organization',
            name: "taxomate",
        },
        publisher: {
            '@type': 'Organization',
            name: "taxomate",
        },
        // datePublished: '2019-01-18T10:30:00+01:00',
        // dateModified: buildTime,
        image: {
            '@type': 'ImageObject',
            url: `${image}`,
        },
    }


    return (
        <>
        <Helmet

            htmlAttributes={{
                lang,
            }}
            title={title}
            titleTemplate={`%s | ${site.site.siteMetadata.title}`}
            link={[
                {
                    rel: 'canonical',
                    href:
                        lang === "en" ?

                        `${host}${originalPathname}`
                        :
                        `${host}/${lang}${originalPathname}`
                },
                {
                    rel: 'alternate',
                    hrefLang: 'x-default',
                    href: `${host}${originalPathname}`,
                },
                //exclude non-sites
                ...(alternate === true ?
                site.allLocale.nodes.map(lng => ({
                    rel: 'alternate',
                    hrefLang: lng.language,
                    href:

                        lng.language === "en" ?

                            `${host}${originalPathname}`
                            :
                            `${host}/${lng.language}${originalPathname}`

                })) : "")


            ]}



            meta={[
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    property: `og:title`,
                    content: title,
                },
                {
                    property: `og:description`,
                    content: metaDescription,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    property: `og:url`,
                    content: canonical,
                },
                {
                    name: `twitter:title`,
                    content: title,
                },
                {
                    name: `twitter:description`,
                    content: metaDescription,
                },
                {
                    name: `og:image`,
                    content: image,
                },
                {
                    name: "twitter:card",
                    content: "summary_large_image",
                },


            ]
            //     .concat(
            //         metaImage
            //             ? [
            //                 {
            //                     property: "og:image",
            //                     content: image,
            //                 },
            //                 {
            //                     name: "twitter:card",
            //                     content: "summary_large_image",
            //                 },
            //             ]
            //             : [
            //                 {
            //                     name: "twitter:card",
            //                     content: "summary",
            //                 },
            //             ]
            //     )

                .concat(meta)}


        >
            {/* Insert schema.org data conditionally (webpage/article) + everytime (breadcrumbs) */}
            {!article && <script type="application/ld+json">{JSON.stringify(schemaOrgWebPage)}</script>}
            {article && <script type="application/ld+json">{JSON.stringify(schemaArticle)}</script>}
            {FAQ && <script type="application/ld+json">{JSON.stringify(schemaFAQ)}</script>}
            <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
            </Helmet>

            <Facebook
                description={description}
                image={image}
                title={title}
                type={article ? 'article' : 'website'}
                url={url}
                locale={lang}
                name={facebook}
            />

            {/* <Twitter title={title} image={image} desc={description} username={twitter} /> */}


        </>

    )
}
SEO.defaultProps = {
    lang: `en`,
    meta: [],
    description: ``,
    image: `https://res.cloudinary.com/taxomate/image/upload/v1630007980/taxomate-social-card_tozy38.jpg`,
    article: false,
    FAQ: false,
}
SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
    image: PropTypes.string,
    pathname: PropTypes.string,
    article: PropTypes.bool,
    FAQ: PropTypes.bool,


}
export default SEO